@import 'assets/styles/Global.scss';

body {
    /* width */
    &::-webkit-scrollbar {
        width: 2px;
        height: 5px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: #F6F6F6; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: gray;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
}
.switch-wrapper {
    position: relative;
}

.highlight {
    transition: var(--transition);
    &:hover { 
        color: var(--color-orange) !important;
    }
}
// .switch-wrapper > div {
//     position: absolute;
// }

.connect-modal,
.mint-modal,
.collateral-confirm-modal {
    .ant-modal-content {
        background: #1B1B1B;
        border: 1px solid #303030;
        box-sizing: border-box;
        box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        .ant-modal-body {
            padding: 0;
            border-radius: 8px;
        }
    }
}

.scrollbar {
    /* width */
    &::-webkit-scrollbar {
        width: 7px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 3px;
        background-color: var(--color-primary);
        transition: 0.3 ease;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(247, 196, 8, 0.4);
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

button:disabled,
button[disabled] {
    color: #C4C4C4;
    background-color: #383838 !important;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;

    .MuiButton-label {
        color: #C4C4C4 !important;
    }
}

.ant-select-dropdown-menu-item {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    color: var(--color-text-main);

    &:hover {
        background-color: var(--color-bg-active) !important;
    }
}

.ant-select-dropdown-menu-item-selected {
    color: var(--color-text-main);
    font-weight: 400;
    background-color: var(--color-bg-primary) !important;
}

input {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        padding-right: 22px;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        padding-right: 22px;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        padding-right: 22px;
    }
    &:-moz-placeholder { /* Firefox 18- */
        padding-right: 22px;
    }
}

.proposal-detail {
    table {
        border: 1px solid #ccc;
    }

    td, th {
        border-top-width: 1px;
        border-top-style: solid; /* double, dashed, dotted... */
        border-top-color: #ccc;
        border-right-width: 1px;
        border-right-style: solid; /* double, dashed, dotted... */
        border-right-color: #ccc;
    }
}

a:hover {
    color: var(--color-orange);
}
// Common CSS

.flex {
    display: flex;
}
.flex-column {
    flex-direction: column;
}
.align-center {
    align-items: center;
}
.just-center {
    justify-content: center;
}
.just-between {
    justify-content: space-between;
}
.just-around {
    justify-content: space-around;
}
.just-end {
    justify-content: flex-end;
}
.center {
    text-align: center;
}
.right {
    text-align: right;
}
.left {
    text-align: left;
}
.pointer {
    cursor: pointer;
}
.full {
    width: 100%;
}

.ReactCollapse--collapse {
    transition: height 500ms;
}