@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap);
:root {
  /* Common styles */
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-orange: #F7C408;
  --color-yellow: #F7C408;
  --color-yellow-hover: rgba(247, 196, 8, 0.4);
  --color-btn-color: rgba(0, 0, 0, 0.87);
  --color-purple: #906DE5;
  --color-red: #F24040;
  --color-green: #42CF50;
  --color-blue: #039BE5;
  --color-dark-green: #42CF50;
  --color-dark-grey: #666771;
  /* Background colors */
  --color-bg-main: #141414;
  --color-bg-primary: #1B1B1B;
  --color-bg-active: #141414;
  /* Typography colors */
  --color-text-main: #ffffff;
  --color-text-secondary: rgba(255, 255, 255, 0.3);
  --color-text-inactive: #5e5f6a;
  --color-text-red: #F24040;
  --color-text-green: #42CF50;
  --color-text-blue: #2fe29b;
  --color-input: #252a4a;
  --color-text-footer: rgba(255, 255, 255, 0.5);
  --color-border-main: #EAEAEA;
  --color-primary: #F7C408;
  --color-secondary: #D3D3D3;
  --transition: all 0.3s ease;
  --border-style: 0.5px solid #323232; }

@font-face {
  font-family: AvenirNext;
  src: url(/static/media/AvenirNextLTPro-Regular.b5ed9330.otf) format("opentype"); }

body * {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-stretch: normal;
  font-style: normal; }

body {
  background: url(/static/media/background.fef27e55.png), #141414 no-repeat;
  background: url(/static/media/background.fef27e55.png), var(--color-bg-main) no-repeat;
  background-size: cover; }

a, span, p, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0px; }

.input-error {
  color: #F24040;
  color: var(--color-red);
  font-size: 18px; }

.ant-select .ant-select-selection {
  background-color: transparent;
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 0 2px #323232;
  color: #ffffff;
  color: var(--color-text-main);
  font-size: 17px;
  font-weight: 500;
  color: #ffffff;
  color: var(--color-text-main); }
  .ant-select .ant-select-selection i {
    color: #ffffff;
    color: var(--color-text-main); }
  .ant-select .ant-select-selection:focus {
    box-shadow: 0 0 0 2px #323232; }

.ant-dropdown-menu {
  background-color: #1B1B1B;
  background-color: var(--color-bg-primary); }
  .ant-dropdown-menu .ant-dropdown-menu-item,
  .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
    color: #ffffff;
    color: var(--color-text-main);
    background-color: #1B1B1B;
    background-color: var(--color-bg-primary); }
  .ant-dropdown-menu .ant-dropdown-menu-item-active {
    background-color: #141414;
    background-color: var(--color-bg-main); }

body {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  body::-webkit-scrollbar {
    width: 2px;
    height: 5px; }
  body::-webkit-scrollbar-track {
    background: #F6F6F6; }
  body::-webkit-scrollbar-thumb {
    background: gray; }
  body::-webkit-scrollbar-thumb:hover {
    background: #555; }

.switch-wrapper {
  position: relative; }

.highlight {
  transition: all 0.3s ease;
  transition: var(--transition); }
  .highlight:hover {
    color: #F7C408 !important;
    color: var(--color-orange) !important; }

.connect-modal .ant-modal-content,
.mint-modal .ant-modal-content,
.collateral-confirm-modal .ant-modal-content {
  background: #1B1B1B;
  border: 1px solid #303030;
  box-sizing: border-box;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.25);
  border-radius: 8px; }
  .connect-modal .ant-modal-content .ant-modal-body,
  .mint-modal .ant-modal-content .ant-modal-body,
  .collateral-confirm-modal .ant-modal-content .ant-modal-body {
    padding: 0;
    border-radius: 8px; }

.scrollbar {
  /* width */
  /* Handle */
  /* Handle on hover */ }
  .scrollbar::-webkit-scrollbar {
    width: 7px; }
  .scrollbar::-webkit-scrollbar-thumb {
    -webkit-border-radius: 3px;
    background-color: #F7C408;
    background-color: var(--color-primary);
    -webkit-transition: 0.3 ease;
    transition: 0.3 ease; }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(247, 196, 8, 0.4); }
  .scrollbar ::-webkit-scrollbar-corner {
    background-color: transparent; }

button:disabled,
button[disabled] {
  color: #C4C4C4;
  background-color: #383838 !important;
  border-color: #d9d9d9 !important;
  box-shadow: none !important; }
  button:disabled .MuiButton-label,
  button[disabled] .MuiButton-label {
    color: #C4C4C4 !important; }

.ant-select-dropdown-menu-item {
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  color: var(--color-text-main); }
  .ant-select-dropdown-menu-item:hover {
    background-color: #141414 !important;
    background-color: var(--color-bg-active) !important; }

.ant-select-dropdown-menu-item-selected {
  color: #ffffff;
  color: var(--color-text-main);
  font-weight: 400;
  background-color: #1B1B1B !important;
  background-color: var(--color-bg-primary) !important; }

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  padding-right: 22px; }

input::-moz-placeholder {
  /* Firefox 19+ */
  padding-right: 22px; }

input:-ms-input-placeholder {
  /* IE 10+ */
  padding-right: 22px; }

input:-moz-placeholder {
  /* Firefox 18- */
  padding-right: 22px; }

.proposal-detail table {
  border: 1px solid #ccc; }

.proposal-detail td, .proposal-detail th {
  border-top-width: 1px;
  border-top-style: solid;
  /* double, dashed, dotted... */
  border-top-color: #ccc;
  border-right-width: 1px;
  border-right-style: solid;
  /* double, dashed, dotted... */
  border-right-color: #ccc; }

a:hover {
  color: #F7C408;
  color: var(--color-orange); }

.flex {
  display: flex; }

.flex-column {
  flex-direction: column; }

.align-center {
  align-items: center; }

.just-center {
  justify-content: center; }

.just-between {
  justify-content: space-between; }

.just-around {
  justify-content: space-around; }

.just-end {
  justify-content: flex-end; }

.center {
  text-align: center; }

.right {
  text-align: right; }

.left {
  text-align: left; }

.pointer {
  cursor: pointer; }

.full {
  width: 100%; }

.ReactCollapse--collapse {
  transition: height 500ms; }

:root {
  /* Common styles */
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-orange: #F7C408;
  --color-yellow: #F7C408;
  --color-yellow-hover: rgba(247, 196, 8, 0.4);
  --color-btn-color: rgba(0, 0, 0, 0.87);
  --color-purple: #906DE5;
  --color-red: #F24040;
  --color-green: #42CF50;
  --color-blue: #039BE5;
  --color-dark-green: #42CF50;
  --color-dark-grey: #666771;
  /* Background colors */
  --color-bg-main: #141414;
  --color-bg-primary: #1B1B1B;
  --color-bg-active: #141414;
  /* Typography colors */
  --color-text-main: #ffffff;
  --color-text-secondary: rgba(255, 255, 255, 0.3);
  --color-text-inactive: #5e5f6a;
  --color-text-red: #F24040;
  --color-text-green: #42CF50;
  --color-text-blue: #2fe29b;
  --color-input: #252a4a;
  --color-text-footer: rgba(255, 255, 255, 0.5);
  --color-border-main: #EAEAEA;
  --color-primary: #F7C408;
  --color-secondary: #D3D3D3;
  --transition: all 0.3s ease;
  --border-style: 0.5px solid #323232; }

@font-face {
  font-family: AvenirNext;
  src: url(/static/media/AvenirNextLTPro-Regular.b5ed9330.otf) format("opentype"); }

body * {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-stretch: normal;
  font-style: normal; }

body {
  background: url(/static/media/background.fef27e55.png), #141414 no-repeat;
  background: url(/static/media/background.fef27e55.png), var(--color-bg-main) no-repeat;
  background-size: cover; }

a, span, p, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0px; }

.input-error {
  color: #F24040;
  color: var(--color-red);
  font-size: 18px; }

.ant-select .ant-select-selection {
  background-color: transparent;
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 0 2px #323232;
  color: #ffffff;
  color: var(--color-text-main);
  font-size: 17px;
  font-weight: 500;
  color: #ffffff;
  color: var(--color-text-main); }
  .ant-select .ant-select-selection i {
    color: #ffffff;
    color: var(--color-text-main); }
  .ant-select .ant-select-selection:focus {
    box-shadow: 0 0 0 2px #323232; }

.ant-dropdown-menu {
  background-color: #1B1B1B;
  background-color: var(--color-bg-primary); }
  .ant-dropdown-menu .ant-dropdown-menu-item,
  .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
    color: #ffffff;
    color: var(--color-text-main);
    background-color: #1B1B1B;
    background-color: var(--color-bg-primary); }
  .ant-dropdown-menu .ant-dropdown-menu-item-active {
    background-color: #141414;
    background-color: var(--color-bg-main); }

body {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  body::-webkit-scrollbar {
    width: 2px;
    height: 5px; }
  body::-webkit-scrollbar-track {
    background: #F6F6F6; }
  body::-webkit-scrollbar-thumb {
    background: gray; }
  body::-webkit-scrollbar-thumb:hover {
    background: #555; }

.switch-wrapper {
  position: relative; }

.highlight {
  transition: all 0.3s ease;
  transition: var(--transition); }
  .highlight:hover {
    color: #F7C408 !important;
    color: var(--color-orange) !important; }

.connect-modal .ant-modal-content,
.mint-modal .ant-modal-content,
.collateral-confirm-modal .ant-modal-content {
  background: #1B1B1B;
  border: 1px solid #303030;
  box-sizing: border-box;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.25);
  border-radius: 8px; }
  .connect-modal .ant-modal-content .ant-modal-body,
  .mint-modal .ant-modal-content .ant-modal-body,
  .collateral-confirm-modal .ant-modal-content .ant-modal-body {
    padding: 0;
    border-radius: 8px; }

.scrollbar {
  /* width */
  /* Handle */
  /* Handle on hover */ }
  .scrollbar::-webkit-scrollbar {
    width: 7px; }
  .scrollbar::-webkit-scrollbar-thumb {
    -webkit-border-radius: 3px;
    background-color: #F7C408;
    background-color: var(--color-primary);
    -webkit-transition: 0.3 ease;
    transition: 0.3 ease; }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(247, 196, 8, 0.4); }
  .scrollbar ::-webkit-scrollbar-corner {
    background-color: transparent; }

button:disabled,
button[disabled] {
  color: #C4C4C4;
  background-color: #383838 !important;
  border-color: #d9d9d9 !important;
  box-shadow: none !important; }
  button:disabled .MuiButton-label,
  button[disabled] .MuiButton-label {
    color: #C4C4C4 !important; }

.ant-select-dropdown-menu-item {
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  color: var(--color-text-main); }
  .ant-select-dropdown-menu-item:hover {
    background-color: #141414 !important;
    background-color: var(--color-bg-active) !important; }

.ant-select-dropdown-menu-item-selected {
  color: #ffffff;
  color: var(--color-text-main);
  font-weight: 400;
  background-color: #1B1B1B !important;
  background-color: var(--color-bg-primary) !important; }

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  padding-right: 22px; }

input::-moz-placeholder {
  /* Firefox 19+ */
  padding-right: 22px; }

input:-ms-input-placeholder {
  /* IE 10+ */
  padding-right: 22px; }

input:-moz-placeholder {
  /* Firefox 18- */
  padding-right: 22px; }

.proposal-detail table {
  border: 1px solid #ccc; }

.proposal-detail td, .proposal-detail th {
  border-top-width: 1px;
  border-top-style: solid;
  /* double, dashed, dotted... */
  border-top-color: #ccc;
  border-right-width: 1px;
  border-right-style: solid;
  /* double, dashed, dotted... */
  border-right-color: #ccc; }

a:hover {
  color: #F7C408;
  color: var(--color-orange); }

.flex {
  display: flex; }

.flex-column {
  flex-direction: column; }

.align-center {
  align-items: center; }

.just-center {
  justify-content: center; }

.just-between {
  justify-content: space-between; }

.just-around {
  justify-content: space-around; }

.just-end {
  justify-content: flex-end; }

.center {
  text-align: center; }

.right {
  text-align: right; }

.left {
  text-align: left; }

.pointer {
  cursor: pointer; }

.full {
  width: 100%; }

.ReactCollapse--collapse {
  transition: height 500ms; }

