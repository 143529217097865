@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

:root {
    /* Common styles */
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-orange: #F7C408;
    --color-yellow: #F7C408;
    --color-yellow-hover: rgba(247, 196, 8, 0.4);
    --color-btn-color: rgba(0, 0, 0, 0.87);
    --color-purple: #906DE5;
    --color-red: #F24040;
    --color-green: #42CF50;
    --color-blue: #039BE5;
    --color-dark-green: #42CF50;
    --color-dark-grey: #666771;
    /* Background colors */
    --color-bg-main: #141414;
    --color-bg-primary: #1B1B1B;
    --color-bg-active: #141414;
    /* Typography colors */
    --color-text-main: #ffffff;
    --color-text-secondary: rgba(255, 255, 255, 0.3);
    --color-text-inactive: #5e5f6a;
    --color-text-red: #F24040;
    --color-text-green: #42CF50;
    --color-text-blue: #2fe29b;
    --color-input: #252a4a;
    --color-text-footer: rgba(255, 255, 255, 0.5);
    --color-border-main: #EAEAEA;
    --color-primary: #F7C408;
    --color-secondary: #D3D3D3;
    --transition: all 0.3s ease;
    --border-style: 0.5px solid #323232;
}
@font-face {
    font-family: AvenirNext;
    src: url("../font/Avenir-next/AvenirNextLTPro-Regular.otf") format("opentype");
}

body * {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    font-stretch: normal;
    font-style: normal;
}

body {
    background: url("../img/background.png"), var(--color-bg-main) no-repeat;
    background-size: cover;
}

a, span, p, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0px;
}

.input-error {
    color: var(--color-red);
    font-size: 18px;
}

.ant-select {
  .ant-select-selection {
    background-color: transparent;
    border: none;
    border-radius: 20px;
    box-shadow: 0 0 0 2px #323232;
    color: var(--color-text-main);
    font-size: 17px;
    font-weight: 500;
    color: var(--color-text-main);
    i {
      color: var(--color-text-main);
    }
    &:focus {
      box-shadow: 0 0 0 2px #323232;
    }
  }
}

.ant-dropdown-menu {
    background-color: var(--color-bg-primary);

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      color: var(--color-text-main);
      background-color: var(--color-bg-primary);
    }

    .ant-dropdown-menu-item-active {
      background-color: var(--color-bg-main);
    }
}
